import * as React from "react";
import { memo } from "react";

import loadable from "@loadable/component";
import { useParams, useSearchParams } from "react-router-dom";

import useReferral from "~features/share/useReferral";

const MintPage = loadable(() => import("./index"));
const MintPageByCollectionId = ({
  useTokenByRevealIdQuery,
}: {
  useTokenByRevealIdQuery?: boolean;
}) => {
  const { collectionId } = useParams();
  const [searchParams] = useSearchParams();
  const tokenId = searchParams.get("tokenId") || undefined;
  const { referrerQueryParamValue: referrer } = useReferral();
  return (
    <MintPage
      collectionId={collectionId}
      referrer={referrer}
      useTokenByRevealIdQuery={useTokenByRevealIdQuery}
      tokenId={tokenId}
    />
  );
};

export default memo(MintPageByCollectionId);
